<template>
  <v-dialog v-model="flagShowUserForm" max-width="500" persistent>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar dense color="blue-grey darken-4" dark>
          {{ item.id >= 0 ? 'Update' : 'Add' }} user
        </v-toolbar>

        <v-form ref="itemForm" v-model="validForm">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="item.first_name"
                    :rules="[rules.required]"
                    label="First name"
                    placeholder="First name"
                    append-icon="mdi-pencil"
                    dense
                    outlined
                    autofocus
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    v-model="item.last_name"
                    :rules="[rules.required]"
                    label="Last name"
                    placeholder="Last name"
                    append-icon="mdi-pencil"
                    dense
                    outlined
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="item.email"
                    :rules="[rules.required, rules.email]"
                    label="Email"
                    placeholder="Email"
                    append-icon="mdi-email"
                    dense
                    outlined
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    type="number"
                    :rules="[rules.required]"
                    v-model="item.mobile_number"
                    label="Mobile number"
                    placeholder="Mobile number"
                    append-icon="mdi-cellphone"
                    dense
                    outlined
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="item.password"
                    :rules="item.id < 0 || item.password ? [rules.required, rules.minLength] : []"
                    type="password"
                    label="Password"
                    placeholder="Password"
                    append-icon="mdi-lock"
                    dense
                    outlined
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    v-model="item.password_confirm"
                    :rules="item.id < 0 || item.password ? [rules.required].concat(compPasswordConfirmationRule) : []"
                    type="password"
                    label="Password confirm"
                    placeholder="Password confirm"
                    append-icon="mdi-lock"
                    dense
                    outlined
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    v-model="item.rvms"
                    :items="rvms"
                    :rules="[v => !!(v && v.length) || 'At least one item is required']"
                    item-text="title"
                    item-value="id"
                    label="RVMs"
                    append-icon="mdi-recycle"
                    outlined
                    clearable
                    multiple
                    chips
                    class="pt-3"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="success"
              :disabled="!validForm || flagUpdatingItem"
              @click="postItem()"
              class="mr-2"
              small
          >
            <v-progress-circular
                v-if="flagUpdatingItem"
                :size="15"
                :width="1"
                indeterminate
            >
            </v-progress-circular>
            <v-icon v-else>mdi-check</v-icon>
            <span class="ml-2">Save</span>
          </v-btn>

          <v-btn
              color="error"
              @click="$emit('hideForm')"
              class="mr-2"
              small
          >
            <v-icon class="mr-2">mdi-close</v-icon>
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>

  </v-dialog>
</template>

<script>
export default ({
  name: "StaffForm",

  data: () => ({
    item: {},
    validForm: false,
    rules: {
      required: value => !!value || "This field is required",
      minLength: value => (value && value.length > 5) || "6 characters minimum",
      email: value => {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid email format"
      }
    },
  }),
  props: ['propItem', 'rvms', 'flagShowUserForm', 'flagUpdatingItem'],
  computed: {
    compPasswordConfirmationRule() {
      return () =>
          this.item.password === this.item.password_confirm || "Passwords must match";
    }
  },
  watch: {
    propItem: function () {
      Object.assign(this.item, this.propItem)
    }
  },
  methods: {
    postItem() {
      if (this.$refs.itemForm.validate()) {
        this.$emit('upsertUser', this.item)
      }
    },
    cancel() {
      this.$emit('hideForm')
    },
  }
});
</script>

<style scoped>
.v-input {
  font-size: .9em;
  padding-top: 5px !important;
}

</style>
