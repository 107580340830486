var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-6"},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.showForm(-1)}}},[_vm._v("Add Staff Member")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.itemsHeaders,"items":_vm.items,"item-key":"name","loading":_vm.flagLoadingItems},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"pb-5"},[_c('v-progress-circular',{staticClass:"mt-5",attrs:{"rotate":360,"size":30,"width":1,"color":"success","indeterminate":""}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","x-small":"","color":"success"},on:{"click":function($event){return _vm.showForm(index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Update")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.showDeleteConfirmDlg(item, index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"blue-grey darken-4","dark":""}},[_vm._v(" Add Parameter ")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Barcode","placeholder":"Barcode","dense":"","outlined":"","autofocus":""},model:{value:(_vm.currentUser.email),callback:function ($$v) {_vm.$set(_vm.currentUser, "email", $$v)},expression:"currentUser.email"}})],1)],1)],1)],1)]}}]),model:{value:(_vm.flagShowUserForm),callback:function ($$v) {_vm.flagShowUserForm=$$v},expression:"flagShowUserForm"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }