<template>
  <div>
    <breadcrumbs :items="breadcrumbs"/>

    <index
        v-if="currentAction === 'index'"
        :flagLoadingItems="flagLoadingItems"
        :items="items"
        @showForm="showForm($event)"
        @showDeleteConfirmDlg="showDeleteConfirmDlg($event)"
    />

    <user-form
        :propItem="currentUser"
        :rvms="rvms"
        :flagShowUserForm="flagShowUserForm"
        :flagUpdatingItem="flagUpdatingItem"
        @upsertUser="upsertUser($event)"
        @hideForm="flagShowUserForm = false"
    />

    <YesNoDlg
        @yes="deleteUser()"
        @no="flagShowDeleteConfirmation = false"
        :visible="flagShowDeleteConfirmation"
        :width="350"
        title="Delete user"
        :question="`You are about to delete ${currentUser.first_name} ${currentUser.last_name}.`"
        :processing="flagDeletingItem"
    />

    <axios ref="axios" v-on:finish="handleAxiosResponse($event)"/>
  </div>
</template>

<script>
import Index from '@/components/users/staff/Index'
import UserForm from '@/components/users/staff/Form'

export default ({
  name: "ManageStaff",

  data: () => ({
    operationResultMessage: "",
    operationResultColor: "",
    flagShowUserForm: false,
    flagShowDeleteConfirmation: false,
    flagUpdatingItem: false,
    flagDeletingItem: false,
    flagLoadingItems: false,
    breadcrumbs: [
      {text: "Staff members", disabled: true}
    ],
    items: [],
    rvms: [],
    currentAction: 'index',
    currentUser: {},
    currentUserRvms: [],
    currentUserIndex: {},
    endpoint: 'users/staff',
  }),
  components: {Index, UserForm},
  mounted() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.flagLoadingItems = true;

      let config = {
        method: 'get',
        snackbar: false,
        params: {},
        overlay: false,
        url: this.endpoint,
      };

      this.$refs.axios.submit(config);
    },

    showForm(index) {
      this.currentUserIndex = index

      this.currentUser = (index >= 0)
          ? this.items[index]
          : this.getEmptyUser()

      this.flagShowUserForm = true
    },

    getEmptyUser() {
      return {
        id: -1,
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        password: '',
        password_confirm: '',
        rvms: [],
      }
    },

    upsertUser(data) {
      this.flagUpdatingItem = true

      Object.assign(this.currentUser, data)
      delete this.currentUser.rvms
      delete this.currentUser.password_confirm

      this.currentUserRvms = data.rvms;

      let config = {
        method: (this.currentUser.id < 0) ? 'post' : 'put',
        snackbar: true,
        params: {
          user: this.currentUser,
          rvms: data.rvms,
        },
        overlay: false,
        url: this.endpoint,
      };

      this.$refs.axios.submit(config);
    },

    deleteUser() {
      this.flagDeletingItem = true

      let config = {
        method: 'delete',
        snackbar: false,
        params: {
          userId: this.currentUser.id,
        },
        overlay: true,
        url: this.endpoint,
      }

      this.$refs.axios.submit(config)
    },

    handleAxiosResponse(event) {
      if (event.data.result.code === 200) {
        let response = event.data.result.response
        this.items = response.items.original

        this.flagLoadingItems = false


        switch (event.verb) {
          case "get":
            this.flagLoadingItems = false
            this.rvms = response.rvms
            break;
          case "post":
          case "put":
            this.flagUpdatingItem = false
            this.flagShowUserForm = false
            break
          case "delete":
            this.flagDeletingItem = false
            this.flagShowDeleteConfirmation = false
            break
          default:
            break
        }
      }
    },

    showDeleteConfirmDlg(data) {
      this.currentUserIndex = data.index;
      this.currentUser = data.item

      this.flagShowDeleteConfirmation = true
    },
  }
});
</script>
