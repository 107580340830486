<template>
  <div class="ml-6">
    <v-row class="mt-1">
      <v-col cols="11">
        <v-btn @click="showForm(-1)" color="success">Add Staff Member</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="11">
        <v-data-table
            dense
            :headers="itemsHeaders"
            :items="items"
            item-key="name"
            class="elevation-1"
            :loading="flagLoadingItems"
        >
          <template v-slot:loading>
            <div class="pb-5">
              <v-progress-circular
                  :rotate="360"
                  :size="30"
                  :width="1"
                  color="success"
                  indeterminate
                  class="mt-5"
              >
              </v-progress-circular>
            </div>
          </template>

          <template v-slot:item.actions="{ item, index }">
            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text x-small color="success" @click="showForm(index)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Update</span>
            </v-tooltip>

            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text x-small color="error" @click="showDeleteConfirmDlg(item, index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
        v-model="flagShowUserForm"
        max-width="500"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar dense color="blue-grey darken-4" dark>
            Add Parameter
          </v-toolbar>

          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model="currentUser.email"
                    label="Barcode"
                    placeholder="Barcode"
                    dense
                    outlined
                    autofocus
                />
              </v-col>
            </v-row>
          </v-container>


        </v-card>
      </template>
    </v-dialog>

  </div>
</template>

<script>
import UserForm from '@/components/users/staff/Form'

export default ({
  name: "StaffIndex",

  data: () => ({
    itemsHeaders: [
      {text: "ID", value: "id"},
      {text: "First name", value: "first_name"},
      {text: "Last name", value: "last_name"},
      {text: "Email", value: "email"},
      {text: "Phone", value: "mobile_number"},
      {text: "Actions", value: "actions", sortable: false}
    ],
    rules: {
      required: value => !!value || "This field is required",
      email: value => {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid email format"
      }
    },
    flagUpdatingItem: false,
    currentUser: {},
    flagShowUserForm: false,
    currentUserIndex: {},
  }),
  props: ["items", "flagLoadingItems"],
  components: {UserForm},
  methods: {
    showForm(index) {
      this.$emit('showForm', index)
    },
    showDeleteConfirmDlg(item, index) {
      this.$emit('showDeleteConfirmDlg', {index: index, item: item})
    },
  }
});
</script>
